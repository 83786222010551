#popup{
	top:0;
	position: absolute;
	height: fit-content;
	width: 100%;
	z-index: 100;
	background-color: #f6f6f6;
	border-bottom: 5px solid grey;
}
.close{
	font-color: red;
	position: absolute;
	display: inline-block;
	top: 15px;
	right: 25px;
}

/* === Form === */
