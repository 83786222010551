.ternak-card p {
	margin-bottom: 0;
	line-height: 1em;
}

.ternak-card .box-img {
	width: fit-content;
    border-radius: 10px;
    box-shadow: 0 5px 10px -3px;
}
.ternak-card .sakit{ background-color: #ECD612 !important}
.ternak-card .sehat{ background-color: #178E2B !important}
.ternak-card .mati{ background-color: #CE1133 !important}
.ternak-card .dijual{ background-color: #1E73D7 !important}
.ternak-card .dibeli{ background-color: #731782 !important}

.alumni-card p{
	line-height: 1.2em;
	margin-bottom: 0;
}