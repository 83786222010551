.bismillah{
    color: white;
    background-color: darkgreen;
    display: inline-block;
    padding: 5px;
  }
  .tagLineAtas{
    margin-top: 20px;
  }
  .tagLineAtas h2{
    color: darkgreen;
    font-style: italic;
    font-size: 1.5em;
    line-height: .7;
  }
  .tagLineBawah{
    margin-top: 110px;
    padding-bottom: 50px;
    text-align: center;
    color: purple;
  }
  .tagLineBawah h2{
    font-size: 1.5em;
    line-height: .7;
  }
  