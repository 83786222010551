/* === Sidebar === */
.fullscreen-blur{
	position: fixed;
	display: inline-block;
	top: 0;
	bottom: 0;
	left: 0;
	width: 0;
	z-index: 100;
	background-color: #5550;
	transition: background-color 1s;
}
.fullscreen-blur.opened{
	width: 100%;
	background-color: #555c;
}
.sidebar{
	padding: 15px;
	text-align: right;
	width: 70%;
	position: fixed;
	right: -100%;
	transition: right 1s;
	bottom: 0;
	top: 0;
	border-radius: 30px 0 0 30px;
	z-index: 101;
	background-color: #eee;
}
.sidebar.opened{
	right: 0;
}
.sidebar img{
	width: 80%;
	margin: 5px;
}
.sidebar a{
	color: #444;
	font-size: 1.3em;
}
.sidebar .copyright{
	display: block;
	width: 100%;
	position: absolute;
	bottom: 0px;
	text-align: center;
	font-style: italic;
	margin: 0 auto;
}
.sidebar p{
	text-align: center;
}
