.search-wrapper{
	/*border: 1px solid black;*/
}
.search{
	padding: 5px 10px 5px 15px;
	background-color: #fff;
	display: inline-block;
	border-radius: 20px;
	border: 1px solid #f5f5f5;
	width: 100%;
}
.search input{
	margin-left: 5px;
	border: none;
	width: 90%;
}
hr{
	width: 95%;
}