button.main-button{
	color: #f5f5f5;
	font-weight: bold;
	display: inline-block;
	margin: 15px auto;
	padding: 15px 7px;
	background-color: #187D25;
	border-radius: 8px;
	border: none;
	width: 80%;
}