/* === Navigasi === */
nav p{
	line-height: 1em;
	margin-bottom: 0;
	font-weight: 500;
}
nav hr{
	width: 100%;
	border-bottom: 1px solid #aaa;
}
nav .nav{
	padding-bottom: 10px;
	border-bottom: 1px solid #aaa;
    cursor: pointer;
}
nav .nav.active-ih{
	padding-bottom: 10px;
	padding-bottom: 9px;
	border-bottom: 2px solid #3e3;
}


/* === Section Input === */
section#input label{
	font-size: .8em;
}


/* === Section History === */
section#history{
	background-color: #fff;
	border-radius: 20px 20px 0 0;
	padding-top: 20px;
	margin: 0 10px;
}

