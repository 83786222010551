/* === Header ==== */
.top-header .logo{
	display: inline-block;
	width: 25%;
	padding: 15px;
}
.top-header{
	box-shadow: 5px 0 grey;
	z-index: 10;
}


/* === Main === */
.second-header{
	/* margin: 0 2px; */
	background-color: #187D25;
	border-radius: 0 0 30px 30px;
}
.text-grey{
	color: #555;
}


/* === Box Bawah === */
.box-bawah{
	background-color: #f5f5f5;
	margin: 0 5px;
	border-radius: 30px 30px 0 0;
}


/* === Footer === */
.space{
	height: 55px;
}
footer{
	z-index: 1;
	box-shadow: 0 1px 3px grey;
	position: fixed;
	width: 100%;
	bottom: 0;
	height: 65px;
	padding-top: 10px;
	background-color: #fafafa;
	border-radius: 30px 30px 0 0;
}

/* === Modal === */
.modal-content{
	background-color: #f6f6f6!important;
}

/* === JsAction ===*/
